import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import '../styles/templates/pageDetailAds.css'
import Control from "../components/control";
import ReactMarkdown from "react-markdown";
import '../styles/theme/font-size.css'
import {Helmet} from "react-helmet";
import '../styles/theme/global.css'
import Vimeo from '@u-wave/react-vimeo';


const pageDetailAds = ({data, pageContext}) => {
    const ads = data.strapiAds
    const {next, prev} = pageContext
    return (
        <>
            <Helmet>
                <title>Ebisu Records - {ads.AdsTitle}</title>
                <meta property={'og:image'} content={`https://www.ebisurecords.fr${ads.ImageSeo.publicURL}`}/>
                <meta name={"description"} content={ads.DescriptionSeo}/>
                <meta property={"og:title"} content={ads.TitleSeo}/>
                <meta property={"og:type"} content={"website"}/>
                <meta property={'og:description'} content={ads.DescriptionSeo}/>
                <meta property={"og:url"} content={'https://www.ebisurecords.fr'}/>
                <meta property={"og:site_name"} content={"ebisurecords"}/>
                <meta property={"og:locale"} content={"fr_FR"}/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content={ads.TitleSeo}/>
                <meta property="twitter:description" content={ads.DescriptionSeo}/>
                <meta property="twitter:image"
                      content={`https://www.ebisurecords.fr${ads.ImageSeo.publicURL}`}/>
            </Helmet>
            <>
                <Header data={data}/>
                <Layout className={'page-brands'}>
                    <section className={'d-flex flex-column justify-content-between control-spacing'}>
                        <div className={'container-detail-information-product'}>
                            <div className={'container-video embed-container'}>
                                <Vimeo video={ads.videoId}/>
                            </div>
                            <div className={'container-page d-flex col-12 justify-content-between mt-2'}
                                 style={{color: "white"}}>
                                <div className={'container-information'}>
                                    <div className={'text-very-small'}>
                                        <div className={'categories-pageDetail'}>{ads.Categorie}</div>
                                        <div className={'logo-detail-page'} style={{
                                            backgroundImage: `url('${ads.AdsLogo.publicURL}')`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain"
                                        }}><></>
                                        </div>
                                    </div>
                                </div>
                                <div className={'container-description text-very-small col-lg-6'}>
                                    <ReactMarkdown>{ads.Descriptions}</ReactMarkdown>
                                </div>
                                <div className={'information-partenaire d-flex text-very-small'}>
                                    <div className={'d-flex flex-column justify-content-between container-text-info'}>
                                        <div className={'mr-4 container-text-info'}>
                                            <span
                                                style={{color: 'grey'}}>{ads.InformationProduction[0] ? `${ads.InformationProduction[0].InformationLabel}: ` : null}</span>{ads.InformationProduction[0] ? ads.InformationProduction[0].InformationContent : null}
                                        </div>
                                        <div>
                                            <span
                                                style={{color: 'grey'}}>{ads.InformationProduction[2] ? `${ads.InformationProduction[2].InformationLabel}: ` : null}</span>{ads.InformationProduction[2] ? ads.InformationProduction[2].InformationContent : null}
                                        </div>
                                    </div>
                                    <div className={'d-flex flex-column justify-content-between'}>
                                        <div className={'mr-4 container-text-info'}>
                                            <span
                                                style={{color: 'grey'}}>{ads.InformationProduction[1] ? `${ads.InformationProduction[1].InformationLabel}: ` : null}</span>{ads.InformationProduction[1] ? ads.InformationProduction[1].InformationContent : null}
                                        </div>
                                        <div><span
                                            style={{color: 'grey'}}>{ads.InformationProduction[3] ? `${ads.InformationProduction[3].InformationLabel}: ` : null}</span>{ads.InformationProduction[3] ? ads.InformationProduction[3].InformationContent : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Control classNames={'Controler'} prev={prev} next={next} pathPrev={prev ? prev.slug : null}
                                 pathNext={next ? next.slug : null}
                                 slug={ads.Categorie}/>
                    </section>
                </Layout>
            </>
        </>
    )
}
export const AdsQuery = graphql`
    query AdsQuery($slug: String!) {
        strapiAds (
            slug: { eq: $slug }
        ) {
            AdsTitle
            Categorie
            Descriptions
            videoId
            slug
            id
            InformationProduction {
                InformationContent
                InformationLabel
            }
            AdsLogo {
                publicURL
            }
            ImageSeo {
                publicURL
            }
            TitleSeo
            DescriptionSeo
        }
        strapiHeader {
            id
            ItemsNav {
                Item
            }
            Logo {
                publicURL
            }
        }
        strapiThemeChooser {
            Themes {
                button
                slug
                id
                title
                imageChooser {
                    publicURL
                }
            }
        }
    }
`


export default pageDetailAds
